import React, { useCallback, useEffect, useMemo } from "react";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Regions from "@/features/explore/maps/MapDiagram/Diagrams/Regions";
import WholeUK from "@/features/explore/maps/MapDiagram/Diagrams/WholeUK";
import { useFilters } from "@/providers/FiltersProvider";

import Constituencies from "./Diagrams/Constituencies";
import LocalAuthoritiesNew from "./Diagrams/LocalAuthoritiesNew";
import UKNations from "./Diagrams/UKNations";
// import UKNationsNew from "./Diagrams/UKNationsNew";

/**
 * The Gender Index Interactive Maps - Map Diagram
 */

interface IMapDiagram {
    modalIsOpen: boolean;
    svgId: string;
    disableZoom?: boolean;
    disableInfo?: boolean;
}
const MapDiagram = ({ modalIsOpen, svgId, disableZoom, disableInfo }: IMapDiagram) => {
    const { mainFilters } = useFilters();

    useEffect(() => {
        if (disableZoom) return;
        const svg = document.getElementById(svgId);
        if (svg) {
            const zoomScale = 1.1;
            let svgX = 0, svgY = 0;
            let svgWidth = 0; // assuming viewBox is square for now so svgWidth serves as svgHeight
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let origSvgX = 0, origSvgY = 0, origSvgWidth = 0;

            let svgPanStartX = 0, svgPanStartY = 0;
            let panning = false;
            const getViewBox = (first = false) => {
                if (svg) {
                    const viewBox = (svg as any).getAttribute("viewBox").split(" ");
                    svgX = parseFloat(viewBox[0]);
                    svgY = parseFloat(viewBox[1]);
                    svgWidth = parseFloat(viewBox[2]);

                    if (first) {
                        origSvgX = svgX;
                        origSvgY = svgY;
                        origSvgWidth = svgWidth;
                    }
                }
            };

            const setViewBox = () => {
                const viewBox = [svgX, svgY, svgWidth, svgWidth].join(" ");
                svg.setAttribute("viewBox", viewBox);
            };

            getViewBox(true);
            // setViewBox();

            const pan = (e: any) => {
                const [svgPanEndX, svgPanEndY] = screenToSvgCoords(e.pageX, e.pageY);
                svgX += svgPanStartX - svgPanEndX;
                svgY += svgPanStartY - svgPanEndY;
                setViewBox();
            };

            svg.onmousedown = function (e) {
                [svgPanStartX, svgPanStartY] = screenToSvgCoords(e.pageX, e.pageY);
                panning = true;
            };

            svg.onmouseup = function (e) {
                if (panning) {
                    panning = false;
                    pan(e);
                }
            };

            svg.onmousemove = function (e) {
                if (panning) {
                    pan(e);
                }
            };

            svg.onwheel = function (e) {
                let scaleFactor = 1;
                if (e.deltaY < 0) {
                    scaleFactor = 1 / zoomScale;
                } else if (e.deltaY > 0) {
                    scaleFactor = zoomScale;
                }

                const oldWidth = svgWidth;
                svgWidth *= scaleFactor;
                svgX += (oldWidth - svgWidth) / 2;
                svgY += (oldWidth - svgWidth) / 2;
                if (panning) {
                    pan(e);
                } else {
                    setViewBox();
                }
            };

            // svg.ondblclick = function (e) {
            //     svgX = origSvgX;
            //     svgY = origSvgY;
            //     svgWidth = origSvgWidth;
            //     setViewBox();
            // };

            const screenToSvgCoords = (screenX: number, screenY: number) => {
                function convert(screenCoord: number, screenOffset: number, screenSize: number, svgCoord: number, svgSize: number) {
                    return (svgSize / screenSize) * (screenCoord - screenOffset) + svgCoord;
                }
                const rect = svg.getBoundingClientRect();
                const x = convert(screenX, rect.x, rect.width, svgX, svgWidth);
                const y = convert(screenY, rect.y, rect.height, svgY, svgWidth);
                return [x, y];
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateMap = useMemo(() => {
        if (!mainFilters) return null;
        const filtersKeys = Object.keys(mainFilters);
        if (!disableInfo) {
            if (modalIsOpen) {
                setTimeout(() => {
                    Array.from(document.querySelectorAll("g[data-code], path[data-code]")).forEach(el => {
                        el.classList.remove("in-active");
                        el.classList.remove("active");
                        // el.removeEventListener("mouseover", () => {
                        //     console.log("ee");
                        // });
                    });
                    document.querySelectorAll(".map-modal svg").forEach(svg => {
                        svg.addEventListener("mouseout", () => {
                            Array.from(document.querySelectorAll(".map-modal g[data-code], path[data-code]")).forEach(el => {
                                el.classList.remove("in-active");
                                el.classList.remove("active");
                            });
                        });
                        Array.from(document.querySelectorAll(".map-modal g[data-code], path[data-code]")).forEach((element: any) => {
                            const areaId = element.dataset["id"];
                            function itemMouseOver() {
                                Array.from(document.querySelectorAll(".map-modal g[data-code], path[data-code]")).forEach((_element: any) => {
                                    if (_element.dataset["id"] !== areaId) {
                                        _element.classList.add("in-active");
                                        _element.classList.remove("active");
                                    } else {
                                        _element.classList.add("active");
                                        _element.classList.remove("in-active");
                                    }
                                });
                            }
                            element.addEventListener("mouseover", itemMouseOver);
                        });
                    });
                }, 100);
            } else {
                setTimeout(() => {
                    document.querySelectorAll(".MapDiagram svg").forEach(svg => {
                        svg.addEventListener("mouseout", () => {
                            Array.from(document.querySelectorAll("g[data-code], path[data-code]")).forEach(el => {
                                el.classList.remove("in-active");
                                el.classList.remove("active");
                            });
                        });
                        Array.from(document.querySelectorAll("g[data-code], path[data-code]")).forEach((element: any) => {
                            const areaId = element.dataset["id"];
                            function itemMouseOver() {
                                Array.from(document.querySelectorAll("g[data-code], path[data-code]")).forEach((_element: any) => {
                                    if (_element.dataset["id"] !== areaId) {
                                        _element.classList.add("in-active");
                                        _element.classList.remove("active");
                                    } else {
                                        _element.classList.add("active");
                                        _element.classList.remove("in-active");
                                    }
                                });
                            }
                            element.addEventListener("mouseover", itemMouseOver);
                        });
                    });
                }, 0);
            }
        }
        if (filtersKeys.includes("LL001")) {
            return <UKNations svgId={svgId} />;
        }
        if (filtersKeys.includes("LL002")) {
            return <Regions svgId={svgId} />;
        }
        // if (filtersKeys.includes("LL003")) {
        //     return <LocalEnterprisePartnership svgId={svgId} />;
        // }
        if (filtersKeys.includes("LL004")) {
            // return <LocalAuthorities svgId={svgId} />;
            return <LocalAuthoritiesNew svgId={svgId} />;
        }
        if (filtersKeys.includes("LL005")) {
            return <Constituencies svgId={svgId} />;
        }
        return <WholeUK svgId={svgId} />;

    }, [disableInfo, mainFilters, modalIsOpen, svgId]);

    const onBtnClick = useCallback((s: "+" | "-") => {
        const svg = document.getElementById(svgId);
        const zoomScale = 1.1;
        let svgX = 0, svgY = 0;
        let svgWidth = 0; // assuming viewBox is square for now so svgWidth serves as svgHeight
        if (svg) {
            const getViewBox = () => {
                if (svg) {
                    const viewBox = (svg as any).getAttribute("viewBox").split(" ");
                    svgX = parseFloat(viewBox[0]);
                    svgY = parseFloat(viewBox[1]);
                    svgWidth = parseFloat(viewBox[2]);
                }
            };
            getViewBox();
            const setViewBox = () => {
                const viewBox = [svgX, svgY, svgWidth, svgWidth].join(" ");
                svg.setAttribute("viewBox", viewBox);
            };

            const scaleFactor = s === "+" ? 1 / zoomScale : zoomScale;

            const oldWidth = svgWidth;
            svgWidth *= scaleFactor;
            svgX += (oldWidth - svgWidth) / 2;
            svgY += (oldWidth - svgWidth) / 2;
            setViewBox();
        }

    }, [svgId]);
    return (
        <div className="MapDiagram m-auto">
            {
                !disableZoom
                && <div className="zoom-btn-group">
                    <button onClick={() => onBtnClick("+")}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button onClick={() => onBtnClick("-")}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                </div>
            }
            {generateMap}
        </div>
    );
};

export default MapDiagram;
